.users-container .users-info .user-details {
  vertical-align: middle;
}
.student-categories-container {
  /* box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1); */
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 2px 3px #16191e1c !important;
}
.edit-form .MuiFab-primary {
  background-color: #0080c1;
}
.edit-form .MuiChip-colorPrimary {
  background-color: #91c515;
}
.edit-form .MuiInputBase-input {
  transition: all 0.3s;
}
.edit-form .user-input-area .MuiInputBase-formControl:hover {
  border-color: #91c515;
}
.edit-form .user-input-area label:hover {
  color: #91c515;
}
.selection-container {
  max-height: 400px;
  overflow-y: auto;
}
.sm-img {
  width: 37px;
  height: 37px;
  object-fit: cover;
}

.post-grid .card.comment-card {
  box-shadow: none !important;
  border-radius: 0 !important;
  border: none;
}
.post-grid .card.comment-card .media img {
  margin-top: -5px;
}
.light-gray-txt {
  color: #848484;
}
.btn-link {
  color: #1d62cb;
  font-weight: 500;
  text-decoration: underline;
  font-family: proximaNovaSemibold;
}
.btn-link:hover,
.btn-link:focus {
  color: #1d62cb;
  text-decoration: none;
}
.comment-list {
  display: inline-block;
}
.comment-list .btn-link {
  padding-left: 7px !important;
  font-size: 14px;
}
.comment-list path {
  fill: #1d62cb;
  stroke: #1d62cb;
  stroke-width: 0.1px;
}
.comment-card h5 {
  font-size: 14px;
}
.comment-buuble {
  background: #f2f5f8 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-family: proximaNovaSemibold;
  line-height: 1.3;
  font-size: 13px;
  position: relative;
}
.comment-buuble .btn-link {
  padding: 0 8px;
  font-size: 12px;
}
.comment-buuble .ref-menu-btn {
  position: absolute;
  top: 4px;
  right: 1px;
}
/*scroll style*/
.scroll-div::-moz-scrollbar {
  width: 6px;
}
.scroll-div::-ms-scrollbar {
  width: 6px;
}
.scroll-div::-webkit-scrollbar {
  width: 6px;
}
.scroll-div::-moz-scrollbar-track {
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scroll-div::-ms-scrollbar-track {
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scroll-div::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scroll-div::-moz-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 0px solid #d0d8e5;
  border-radius: 50px;
}
.scroll-div::-ms-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 0px solid #d0d8e5;
  border-radius: 50px;
}
.scroll-div::-webkit-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 0px solid #d0d8e5;
  border-radius: 50px;
}
.create-footprint-form input {
  padding-left: 14px !important;
  border-bottom-left-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.create-footprint-form input,
.create-footprint-form .input-group-prepend .input-group-text {
  background: #eef2f5;
  border-color: rgba(0, 0, 0, 0.02);
  box-shadow: none;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.create-footprint-form input:focus + .input-group-prepend .input-group-text {
  background: #e6e9ec;
  border-color: rgba(0, 0, 0, 0.02);
}
.create-footprint-form input:hover,
.create-footprint-form input:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0.02);
  background-color: #e6e9ec;
}
.upload-btns .media-upload path {
  stroke: white;
}
.active-checkbox {
  color: #1d62cb;
}
/*carousel*/
.carousel-inner {
  padding-bottom: 8px;
}
.carousel-indicators {
  margin-bottom: 0;
  bottom: -19px;
}
.carousel-indicators li {
  width: 9px;
  height: 9px;
  background-color: #d9d6d6;
  border-radius: 50%;
}
.carousel-indicators .active {
  background-color: #1d62cb;
}

.app-logo path.st0 {
  fill: #4f4f4f !important;
}
.post-grid .card-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.modal-header .close:focus {
  outline: none;
}

/* footprint upload */
.std-footprint-mdl .modal-dialog.modal-sm {
  max-width: 500px;
}
.std-footprint-mdl .modal-body {
  padding-right: 0;
  background-color: #f8f8f8;
}
.std-footprint-mdl .new-post-container {
  padding-top: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
.std-footprint-mdl .user-avatar {
  margin-right: 8px;
}

.users-container .user-details.user-name {
  display: inline-block;
  line-height: 0.8rem;
}
.contains-role .users-info .user-name {
  display: inline-block;
  line-height: 1.12rem;
}
.new-post-container {
  padding-right: 20px;
}
.new-post-container .user-details .user-name {
  font-weight: 400;
}
.quick-upload-container .user-avatar img,
.quick-upload-container .user-avatar {
  margin-right: 8px;
}
.create-footprint-form
  .post-form
  .input-group-prepend:last-child
  .input-group-text {
  padding-bottom: 2px;
  padding-top: 2px;
}
.selection-container {
  flex: 1;
  height: 100%;
  max-height: 461px;
}
.selection-container .uploaded-asstes li {
  position: relative;
}
.selection-container .uploaded-asstes img {
  max-width: 78px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
}
.uploaded-asstes .btn-remove {
  position: absolute;
  right: 3px;
  height: 20px;
  top: -13px;
  display: inline-block;
  z-index: 20;
  opacity: 1;
}
.uploaded-asstes .btn-remove:focus,
.uploaded-asstes .btn-remove::selection {
  outline: none;
}
.uploaded-asstes .btn-remove span {
  font-size: 15px;
  display: inline-block;
  border: 1px solid #fff;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  background: #fff;
  line-height: 14px;
  color: #000;
}
.users-selection .uploaded-asstes {
  overflow-y: auto;
  max-height: 80px;
}
.users-selection .uploaded-asstes li {
  padding-top: 6px;
}
.radio-container.users-selection .btn-group.upload-btns {
  position: absolute;
  right: 13px;
}
.users-selection .users-list-items .list {
  position: relative;
}
.quick-upload-container .new-post-area .MuiFab-primary {
  background-color: #0080c1;
}
.quick-upload-container .chip-box .MuiChip-colorPrimary {
  background-color: #91c515;
}
.app-header {
  box-shadow: 0 0 4px rgba(0, 0, 0.09);
}
.create-footprint-form .user-image img {
  padding: 0;
}
.select-bootstrap {
  font-family: proximaNovaRegular;
  font-size: 14px;
}
.select-bootstrap [class$="control"] {
  border-color: #dedede;
  border-radius: 10px !important;
}
.select-bootstrap [class$="IndicatorsContainer"] {
  width: 30px;
}
.select-bootstrap [class$="indicatorSeparator"] {
  display: none;
}
.select-bootstrap [class$="indicatorContainer"]::before {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 6px solid #2f2f2f;
  font-size: 0;
  line-height: 0;
  float: left;
  content: " ";
}
.select-bootstrap [class$="control"]:focus,
.select-bootstrap [class$="control"]:hover,
.select-bootstrap [class$="control"] {
  border-color: #91c515;
  outline: none !important;
  box-shadow: none;
  border-radius: 10px !important;
}
.new-post-area .chip-box {
  max-height: 38px;
  margin-bottom: 0;
  overflow-y: auto;
}

* {
  box-sizing: border-box;
}
html {
  height: 100%;
}
body {
  min-height: 100%;
  background: #f8f8f8;
  overflow-y: scroll;
}
::-ms-scrollbar-track {
  background: none;
}
::-moz-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-track {
  background: none;
}
::-moz-scrollbar {
  width: 10px;
  height: 10px;
}
::-ms-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-moz-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}
::-ms-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 5px;
}
.MuiCircularProgress-root {
  width: 15px !important;
  height: 15px !important;
}

.uploaded-asstes .MuiChip-colorPrimary {
  background-color: #f8fbff;
}
.uploaded-asstes .MuiChip-root:hover {
  background-color: #0080c1;
}
.uploaded-asstes .MuiChip-iconColorPrimary,
.MuiCircularProgress-root.MuiCircularProgress-colorPrimary,
.uploaded-asstes .MuiChip-colorPrimary .MuiChip-label {
  color: #0080c1;
}
.uploaded-asstes .MuiSvgIcon-root {
  fill: #0080c1;
  font-size: 18px;
}
.uploaded-asstes
  .MuiChip-root:hover
  .MuiCircularProgress-root.MuiCircularProgress-colorPrimary,
.uploaded-asstes .MuiChip-root:hover .MuiChip-label {
  color: #f8fbff;
}
.uploaded-asstes .MuiChip-root:hover .MuiSvgIcon-root {
  fill: #f8fbff;
}
.MuiSvgIcon-root {
  margin-right: 4px;
}

.new-post-area .user-input-area input::placeholder,
.new-post-area .form-group textarea::placeholder {
  color: #a5a5a5;
}
.main-sidebar {
  z-index: 1;
}
.sidebar {
  overflow-x: hidden;
}
.user-input-area .MuiSvgIcon-root {
  margin: 0;
}
.btn-shadow,
.edit-profile-btn .blue-button,
.submit-btn {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12) !important;
}
.hidden-div {
  height: 26px;
}
.border-radius-10 {
  border-radius: 10px !important;
}
.user-profile-details .user-avatar img.align-self-center {
  margin-top: -4px;
}
.user-profile-details .media-body .location {
  margin-top: 1px;
}

.post-container {
  position: relative;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 50%;
  z-index: 999;
}
.loader-container div {
  z-index: 999;
}
.loader-new-post {
  text-align: center;
}
.class-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: -77px;
}
#search-std-div {
  min-height: calc(100vh - 40vh);
}
.main-sidebar span.sidebar-arrow {
  display: none;
}
.owl-carousel .owl-stage-outer {
  height: auto;
}
