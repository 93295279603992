/* Extra small devices (phones, 320px and down) */
@media (max-width: 320px) {
  .users-container .users-info .user-details,
  .users-container .users-info .user-details .user-name {
    width: 60px;
  }
}
/* Extra small devices (phones, 350px and down) */
@media (max-width: 350px) {
  .users-container .users-info .user-details,
  .users-container .users-info .user-details .user-name {
    width: 100px;
  }
}
/* Extra small devices (phones, 343px and down) */
@media all and (max-width: 343px) {
  .create-footprint-form .input-group-prepend {
    display: none;
  }
  .create-footprint-form .input-group input[type="text"] {
    border-radius: 10px !important;
  }
  .modal-footer .blue-button {
    min-width: 100%;
  }
  .modal-footer .btn {
    padding: 0.375rem 0.1rem 0.375rem 0;
  }
}
@media screen and (max-width: 400px) {
  .main-header .mobile-toggle {
    width: 23%;
  }
  .contact-details li {
    margin-bottom: 0.5em;
  }
  .other-information {
    padding-top: 10px;
  }
  .modal-footer.no-abs-footer .col-xs-7,
  .modal-footer.no-abs-footer .col-xs-5 {
    width: 50%;
  }
  .modal-footer.no-abs-footer .btn.media-upload {
    padding-right: 0;
  }
  .quick-upload-container .selection-container {
    max-height: 138px;
  }
  .users-container .users-info .footprints .footprints-numbers {
    padding: 2px;
  }
  .profile-container .contact-details {
    margin: 0 !important;
  }
  .profile-container .user-profile-details.user-info {
    margin-top: 0;
  }
  .profile-container .user-location {
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 425px) {
  .user-profile-details .userImage-name {
    margin-right: 5px;
  }
}
@media all and (max-width: 500px) {
  .quick-upload-container .modal-footer {
    padding-top: 0.4rem;
    padding-bottom: 1rem;
  }
  .quick-upload-container .modal-footer.no-abs-footer .blue-button {
    position: relative !important;
  }
  .quick-upload-container .modal-footer.no-abs-footer .blue-button.submit-btn{min-width: 60px; max-width: 85px !important;}
  .large-icon img {
    max-width: 93px;
  }
  .quick-upload-container .modal-footer.no-abs-footer .upload-files{font-size: 12px;}
  .quick-upload-container .modal-footer.no-abs-footer .upload-files .info-btn button{width: 10px;margin-right: 0;margin-left: 3px !important;}
  .quick-upload-container .modal-footer.no-abs-footer .upload-files .material-icons.MuiIcon-root.fa-info{font-size: 12px !important;}
  .one-line-txt {
    max-width: 138px;
  }
  .student-categories-container .student-lists {
    padding-right: 0;
  }
  .student-lists .view-profile {
    text-align: right;
  }
  .app-header .main-header .navbar-nav {
    float: left;
    overflow: hidden;
    cursor: pointer;
    white-space: nowrap;
    vertical-align: middle;
    width: 40px;
  }
  .app-header .main-header .app-logo {
    margin-right: 30px;
    width: 100%;
  }
  .user-profile-details .media-body .location {
    width: 100%;
  }
}
@media all and (max-width: 548px) {
  .footer-uploads .google-drive,
  .footer-uploads .media-upload {
    margin-right: 5px;
  }
  .profile-container .media {
    display: block;
  }
  .profile-container .media-left {
    width: 100%;
    float: left;
    margin: 0;
  }
  .profile-container .media-left .school-details {
    margin: 0 auto 0.75rem auto;
  }
  .search-plus-menu {
    display: none;
  }
  .modal-content .selection-container {
    margin-top: 1px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .quick-upload-container .selection-container {
    height: 92% !important;
  }
  .quick-upload-container .modal-body {
    padding-right: 0;
  }
  .users-selection .users-list-items li .list {
    width: 87%;
  }
  .users-selection .users-list-items li {
    padding: 4px 6px 4px 10px;
    margin-bottom: 0;
  }
  .users-selection .users-list-items li .list .userImage-name,
  .users-selection .users-list-items .uploaded-asstes {
    float: left;
    width: 100%;
  }
  .users-selection .users-list-items .uploaded-asstes li .MuiChip-root {
    font-size: 12px;
  }
  .modal-body .users-selection .search-user .container-title {
    padding-bottom: 0;
  }
  .modal-body .form-group.user-input-area {
    margin-bottom: 0.6rem;
  }
  .nav-sidebar .nav-item {
    padding-bottom: 0;
  }
}

@media all and (max-width: 768px) {
  .carousel-inner .video-player {
    width: 100%;
  }
  .user-input-area .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 2px) scale(0.75);
  }
  .users-selection .uploaded-asstes {
    padding-left: 0;
  }
  .users-selection .uploaded-asstes li {
    margin-right: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
  .app-logo,
  .app-logo svg {
    height: 60px;
  }
  .user-profile-details .user-location {
    margin-bottom: 5px;
  }
  .contact-details.mb-2 {
    margin-top: 5px;
  }
  .user-avatar {
    display: block;
    float: left;
  }
  .total-footprints {
    position: absolute;
    width: max-content;
    text-align: center;
    height: 35px;
    min-height: 32px;
    right: 0;
    top: -25px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 6px 6px 6px 9px;
  }
  .total-footprints .footprints small,
  .total-footprints .graphic {
    display: none;
  }
  .total-footprints .footprints .numbers,
  .total-footprints .numbers {
    font-size: 13px;
    width: auto;
    text-align: center;
  }
  .sidebar-content .radio-container {
    margin-top: 0;
  }
  .sidebar-content .users-container .user-avatar {
    float: none;
  }
}
@media all and (max-width: 776px) {
  .content-wrapper > .content {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .user-info .user-profile-data {
    margin-bottom: 0;
  }
  ul.contact-details {
    margin-bottom: 16px;
  }
  .user-profile-details {
    margin-top: 16px;
  }
  .user-profile-details .user-profile-data li {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  .new-post-area .user-input-area label,
  .new-post-area .user-input-area label[data-shrink="false"] {
    top: -9px;
  }
  .new-post-area .user-input-area input {
    height: 35px;
  }
  .new-post-area .user-input-area .MuiFab-root {
    width: 34px;
    height: 32px;
  }
  .new-post-area .MuiSvgIcon-root {
    font-size: 1.4rem;
  }
  .modal-content .modal-title {
    font-size: 20px;
  }
  .users-selection .userImage-name .user-avatar img {
    width: 35px;
    height: 35px;
  }
  .users-selection .uploaded-asstes {
    max-height: 40px;
  }
  .users-selection .user-name {
    font-size: 14px;
    display: inline-block;
    white-space: nowrap;
    width: 78px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
  .radio-container.users-selection .btn-group.upload-btns {
    padding: 0;
    margin: 2px 0 0 0;
    right: 0;
  }

  .users-selection .users-list-items .list {
    padding: 0;
    margin-bottom: 0;
    background: none;
    box-sizing: border-box;
  }
  .users-selection .users-list-items li {
    background: #efefef;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 6px 10px 6px 10px;
    margin-right: 10px;
  }
  .quick-upload-container .chip-box .MuiChip-colorPrimary {
    height: 26px;
  }
  .std-footprint-mdl .uploaded-asstes {
    max-height: 50px;
  }
  .main-sidebar .user-details {
    height: 202px;
  }
}

@media all and (max-width: 992px) {
  .search-plus-menu {
    display: none;
  }
  .main-header .mobile-toggle {
    width: 40px;
    position: absolute;
    left: 16px;
  }
  .users-container .users-info .user-name {
    width: 75px;
  }
  .modal-body .selection-container {
    padding-left: 20px;
    padding-right: 10px;
  }
  .sidebar-navigation .nav-sidebar .nav-item .nav-link {
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .nav-sidebar .nav-item {
    padding-bottom: 2px;
    padding-top: 2px;
  }
}
@media (max-width: 1024px) {
  .large-icon img {
    max-width: 96px;
  }
  .users-container .users-info .user-name {
    width: 75px;
  }
  .files-icon-holder .btn {
    margin-left: 12px !important;
  }
}

@media all and (max-width: 1200px) {
  .footer-uploads .media-upload .icon {
    float: left;
  }
  .new-post-area .chip-box {
    max-height: 35px;
  }
  .new-post-container {
    padding-top: 10px;
  }
  .new-post-container .new-post-area {
    margin-top: 10px;
  }
  .new-post-area .form-group textarea {
    height: 50px;
    font-size: 14px;
  }
  .modal-footer.no-abs-footer {
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
  }
  .quick-upload-container .selection-container {
    max-height: 160px;
  }
  .modal-dialog {
    padding: 0 20px;
  }
}

@media (min-width: 1440px) {
  .new-post-area .chip-box {
    max-height: 56px;
  }
}
@media all and (max-width: 1440px) {
  /* .student-list {display: none;} */
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Devices larger than 320*/
@media screen and (min-width: 320px) {
  .modal-dialog {
    transition: all 0.5s !important;
    position: absolute !important;
    left: 50%;
    right: 50%;
    margin: 0 !important;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
}

/**** Breakpoints ********/
@media screen and (max-width: 461px) and (min-width: 433px) {
  .quick-upload-container .modal-footer .blue-button {
    position: absolute;
    margin-top: 5%;
  }
}
@media (min-width: 400px) and (max-width: 483px) {
  .modal-footer.no-abs-footer .col-xs-5 {
    width: 40%;
  }
  .modal-footer.no-abs-footer .col-xs-7 {
    width: 60%;
  }
}
@media (max-width: 1080px) and (min-width: 992px) {
  .user-profile-details .user-details {
    float: left;
    margin-right: 14px;
  }
  .user-profile-details .media-body .location {
    float: left;
  }
}
@media (max-width: 900px) and (min-width: 767px) {
  .users-container .users-info .user-details,
  .users-container .users-info .user-details .user-name {
    width: 100px;
  }
}

@media (min-width: 484px) and (max-width: 575px) {
  .modal-footer.no-abs-footer .col-xs-5 {
    width: 70%;
  }
  .modal-footer.no-abs-footer .col-xs-7 {
    width: 30%;
  }
}
@media (max-width: 1280px) and (min-width: 1199px) {
  .users-container .users-info .user-name {
    width: 75px;
  }
  .users-container .users-info .footprints .footprints-text {
    padding-right: 10px;
  }
}

@media only screen and (orientation: landscape) {
}
