// Common theme color variables
$commonThemePrimaryColor: #8fa774;
$commonThemeSecondaryColor: #9db582;
$textSvgPureColor: white;
$textSvglightColor: #ffffff85;
$lightBlueColor: #78a1e0;

// Student theme color variables
$studentThemePrimaryColor: #4b6095;
$studentThemeSecondaryColor: #5b6d9e;

.student-theme .main-sidebar {
  background-color: $studentThemePrimaryColor;
}
.student-theme .sidebar-navigation {
  background-color: $studentThemeSecondaryColor;
}
.student-theme .total-footprints {
  background: $studentThemePrimaryColor;
}
.common-theme .main-sidebar {
  background-color: $commonThemePrimaryColor;
}
.common-theme .sidebar-navigation {
  background-color: $commonThemeSecondaryColor;
}
.common-theme .total-footprints {
  background: $commonThemePrimaryColor;
}
.sidebar-navigation .nav-sidebar .nav-item svg path {
  fill: $textSvgPureColor;
  stroke: $textSvgPureColor;
}
.sidebar-navigation .nav-sidebar .nav-item .nav-link {
  color: $textSvgPureColor;
}
.sidebar-close .sidebar-navigation .nav-sidebar .active .nav-link svg path {
  fill: $textSvgPureColor;
  stroke: $textSvgPureColor;
}
.common-theme .school-details {
  background: $commonThemePrimaryColor;
}
.student-theme .school-details {
  background: $studentThemePrimaryColor;
}
.common-theme .other-information .row-title {
  color: $commonThemePrimaryColor;
}
.student-theme .other-information .row-title {
  color: $studentThemePrimaryColor;
}
// .common-theme .active-class {
//   background: $commonThemePrimaryColor;
// }
// .student-theme .active-class {
//   background: $studentThemePrimaryColor;
// }
/* .common-theme .comment-list path{fill:$lightBlueColor;stroke:$lightBlueColor;} */
