@font-face {
  font-family: proximaNovaBold;
  src: url("../assets/fonts/Proxima-Nova-Bold.ttf");
}
@font-face {
  font-family: proximaNovaRegular;
  src: url("../assets/fonts/ProximaNova-Regular.ttf");
}
@font-face {
  font-family: proximaNovaSemibold;
  src: url("../assets/fonts/proximanova-semibold.ttf");
}
.container-fluid {
  padding: 0;
}
.radio-container-list .custom-checkbox {
  font-size: 14px;
  font-family: proximaNovaRegular !important;
  font-weight: normal !important;
}
.radio-container .radio-container-list li:last-child {
  border-bottom: 0;
}
.users-container .users-info li:last-child {
  border-bottom: 0;
}
.login-wrapper .right-section {
  height: 100%;
  overflow-y: auto;
}
.login-wrapper .login-form .welcome-user h1 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-family: proximaNovaBold;
  font-size: 44px;
  margin-bottom: 12px;
}
.login-wrapper .login-form .welcome-user p {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-size: 16px;
  font-family: proximaNovaRegular;
}
.user-form {
  padding-top: 25px;
}
.user-input-label {
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-size: 18px;
  font-family: proximaNovaSemibold;
}
.user-input-field {
  border: 1px solid #e3e8ec !important;
  border-radius: 138px !important;
  height: 52px !important;
  color: #7c888f !important;
  font-size: 14px !important;
  padding-left: 50px !important;
}
.login-wrapper .login-form form .has-error .help-block {
  color: #65a3c2;
  font-size: 14px;
  font-family: proximaNovaRegular;
  padding-top: 3px;
}
.user-input-field:focus {
  border: 1px solid #88c000 !important;
  outline: none !important;
  box-shadow: none !important;
}
.field-icon {
  position: absolute;
  top: 47px;
  left: 23px;
}
.form-group {
  position: relative;
}
.field-icon svg {
  width: 15px !important;
  height: 15px;
}
.field-icon svg path {
  fill: #7c888f;
}
.user-input-field:focus + .field-icon svg path {
  fill: #88c000;
}
.alert-success {
  padding-top: 2px;
  padding-bottom: 2px;
  margin-bottom: 0;
  padding-left: 7px;
  font-family: proximaNovaRegular;
  background: unset;
  border: 0;
  color: #212529 !important;
}
.alert-success .fa {
  padding-right: 0;
  border: 1px solid;
  border-radius: 50px;
  width: 25px;
  height: 25px;
  background: #91c515 0% 0% no-repeat padding-box;
  color: white;
  font-weight: normal;
  margin-right: 6px;
  text-align: center;
  line-height: 21px;
}
.action-button .btn {
  border-radius: 138px;
  height: 52px;
  line-height: 42px;
  background: #91c515 0% 0% no-repeat padding-box;
  border: 0;
  width: 100%;
  max-width: 205px;
  font-size: 18px;
  font-family: proximaNovaSemibold;
  opacity: 0.8;
}
.action-button .btn:hover {
  opacity: 1;
  background: #91c515 0% 0% no-repeat padding-box !important;
}
.action-button .btn:focus {
  background: #91c515 0% 0% no-repeat padding-box !important;
}
.need-help-button {
  background-color: #0f2444 !important;
}
.forgot-pass {
  font-size: 14px;
  color: #7e8e97;
  font-family: proximaNovaRegular;
  position: absolute;
  right: 0;
  bottom: 15px;
}
.forgot-pass a {
  color: #0080c1;
  text-decoration: underline;
}
div#root {
  background: transparent linear-gradient(180deg, #f8f8f8 0%, #fcfcfc 100%) 0%
    0% no-repeat padding-box;
  height: 100%;
}
.login-wrapper .right-section .login-form {
  width: 100%;
  max-width: 560px;
  margin-left: 11%;
  padding-bottom: 6%;
}
.left-section {
  width: 80%;
}
.login-wrapper {
  height: 100vh;
  overflow: hidden;
}
.login-header {
  text-align: center;
  background: white;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-button .btn:hover {
  background: #007fc0;
}
.post-form button:focus {
  outline: none !important;
  box-shadow: unset !important;
}
.forgot-pass-wrap {
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 2px 3px #16191e1c !important;
  width: 100%;
  max-width: 658px;
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  margin: auto;
  padding: 40px 40px 24px 40px;
  margin-top: 40px;
}
.forgot-pass-wrap h1 {
  color: #000000;
  font-size: 24px;
  font-family: proximaNovaBold;
}

/* Dashboard css */

.user-menu .dropdown-toggle {
  color: black;
  font-size: 16px;
  font-family: ProximaNovaRegular;
  text-decoration: unset;
}
.navbar-custom-menu .user-image {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  box-shadow: 0 0 0 3px #f05c21;
  border-radius: 50%;
  display: inline-block;
  object-fit: cover;
}
.navbar-custom-menu .label-text {
  padding-right: 5px;
}
.navbar-custom-menu .dropdown-menu li a {
  font-size: 13px;
  color: black;
  font-family: ProximaNovaRegular;
}
.app-logo {
  width: 100%;
  max-width: 262px;
  padding-left: 15px;
}
.post-image {
  width: auto;
  text-align: center;
  margin: auto;
}
.search-plus-menu {
  width: 100%;
  padding-right: 20px;
}
.app-options {
  float: right;
  position: relative;
  top: 2px;
}
.app-options .user-avatar {
  width: 36px !important;
  border: 1px solid #ccc;
  padding: 3px;
  height: 36px !important;
  border-radius: 50%;
  margin-right: 5px;
  object-fit: cover;
}
.app-options .dropdown-menu {
  width: 100% !important;
  top: 40px;
  border-radius: 3px !important;
  background: #0080c1;
  min-width: 100%;
  padding-top: 0;
  padding-bottom: 0;
}
.app-header {
  position: fixed !important;
  right: 0;
  left: 0;
  width: 100%;
  background: white;
  z-index: 999;
}
.main-header {
  height: 77px;
  margin-left: 0 !important;
  border-bottom: 0 !important;
}
.search-plus-menu .app-search-field {
  width: 100%;
  max-width: 432px;
  float: left;
}
.app-search-field .input-group-append .btn {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
  background: #f2f4f6;
}
.app-search-field .input-group {
  width: 100% !important;
}
.app-search-field .input-group .form-control:focus {
  box-shadow: none;
  outline: none;
  background: #f5f5f5;
}
.app-search-field .input-group .form-control {
  color: #606060;
  font-size: 13px;
  font-family: proximaNovaRegular;
  border-top-left-radius: 10px;
  background-color: #f2f4f6;
  border: 0;
  border-bottom-left-radius: 10px;
  height: 43px;
}
.app-options .dropdown-toggle {
  color: #000000;
  font-size: 14px;
  font-family: proximaNovaSemibold;
}
.app-options .dropdown-menu .sub-menu-text {
  color: white;
  font-size: 13px;
  font-family: proximaNovaRegular;
  vertical-align: text-bottom;
}
.app-options .dropdown-menu li {
  padding-left: 8px;
}
.app-options .dropdown-menu li:hover {
  background: #88c000;
}
.main-sidebar {
  background-color: #154ea5;
  box-shadow: 0px 3px 26px #00000008;
}
.sidebar .user-details .image .img-circle {
  background: white;
  width: 100px;
  height: 102px;
  border-radius: 0px 110px 110px 110px;
  object-fit: cover;
}
.sidebar .user-details .info p {
  margin-bottom: 0;
  color: white;
  font-size: 15px;
  font-family: proximaNovaRegular;
}
.sidebar .user-details .info a {
  font-size: 19px;
  font-family: proximaNovaSemibold;
  color: white;
  text-transform: capitalize;
}
.sidebar .user-details .info {
  padding-top: 20px;
  padding-bottom: 20px;
}
.sidebar-navigation .nav-sidebar .nav-item .nav-link {
  text-align: left;
  padding-left: 60px;
  padding-right: 0;
  text-transform: capitalize;
  font-size: 14px;
  cursor: pointer;
  font-family: proximaNovaRegular;
}
.nav-sidebar .nav-item {
  padding-bottom: 9px;
}
.main-sidebar .sidebar {
  padding-right: 0;
}
.sidebar-navigation {
  background-color: #1d62cb;
  border-radius: 0px 100px 0px 0px;
  box-shadow: 0px 3px 26px #00000008;
  padding-top: 35px;
  height: 100vh;
  position: absolute;
  width: 100%;
  right: 0;
  left: 0;
}
.main-sidebar .user-details {
  height: 254px;
  padding-top: 45px;
  padding-bottom: 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.sidebar-navigation .nav-sidebar .nav-item .nav-link p:hover {
  text-decoration: underline;
}
.sidebar-navigation .nav-sidebar .nav-item .nav-link:hover {
  color: white;
}
.sidebar-navigation .nav-sidebar .active .nav-link {
  font-weight: 600;
}
.sidebar-navigation .nav-sidebar .nav-item svg {
  vertical-align: sub;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  display: inline-block;
}
.content-wrapper {
  background: transparent !important;
}
.content-wrapper {
  margin-left: 250px;
  padding-top: 80px;
}
.content-wrapper > .content {
  padding-right: 16px !important;
  padding-top: 24px !important;
  padding-left: 30px !important;
  position: relative;
}
.post-grid .card-header .align-self-center.img-circle,
.post-grid .card-header .user-avatar {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  border: 1px solid #88c000;
  margin-right: 13px;
  object-fit: cover;
}
.post-grid .card-header .label-text {
  color: #000000;
  font-family: proximaNovaSemibold;
  font-size: 16px;
  text-transform: capitalize;
}
.post-grid .card-header {
  height: 73px;
  padding-top: 14px;
  border-bottom: 0;
}
.post-grid .card .card-body {
  background: #eef2f5;
  border: 1px solid #eef2f5;
  padding-top: 1px;
}
.post-grid .card {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 2px 3px #16191e1c !important;
}
img {
  max-width: 100%;
}
.post-grid .card .card-body .post-date {
  color: #8c95a2;
  font-family: proximaNovaRegular;
  font-size: 12px;
  padding: 9px 0 4px 0;
  display: inline-block;
}
.back-button svg path {
  fill: white;
}
.paragraph {
  margin-bottom: 6px;
  line-height: 1.2em;
}
.paragraph,
.post-grid .card .card-body .post-description p {
  color: #000000;
  font-size: 14px;
  font-family: proximaNovaSemibold;
}
.post-form textarea,
.post-form input {
  border-top-left-radius: 10px;
  height: 43px;
  background: #eef2f5 0% 0% no-repeat padding-box;
  border-right: 0;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  font-size: 14px;
  font-family: proximaNovaRegular;
  padding: 0 0 0px 20px;
}
.post-form .input-group-text {
  border-left: 0;
  background: #eef2f5;
  border-right: 0;
  box-shadow: 1px 0px 0px 0px #ccc;
  padding-left: 0;
  cursor: pointer;
}
.post-form .input-group-prepend:last-child .input-group-text {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.post-form textarea {
  min-height: 41px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-right: 8px;
}
.post-form textarea:focus,
input:focus {
  outline: none !important;
  box-shadow: none !important;
}
.post-form button {
  background: transparent;
  border: 0;
  color: #91c515;
}
.total-likes ul {
  list-style-type: none;
  padding-left: 0;
  float: left;
  margin-bottom: 0.5rem;
}
.total-likes ul li {
  display: inline-block;
  vertical-align: top;
  padding-right: 15px;
}
.comment-list .fa-commenting-o,
.total-likes .fa-commenting-o {
  font-size: 24px;
}
.comment-list .fa-commenting-o {
  color: #1d62cb;
}
.total-likes .fa-commenting-o {
  color: #a1b0c6;
}

.total-likes .icon.comments {
  cursor: pointer;
}
.total-likes .icon svg {
  width: 14px;
  height: 14px;
  margin-right: 2px;
}
.total-likes ul li .total {
  font-size: 12px;
  vertical-align: text-top;
  color: #8c95a2;
}
.post-grid .card-footer {
  background: transparent;
}
.total-likes .comments svg path {
  fill: #8c95a2 !important;
  stroke: #8c95a2 !important;
}
span.like-action {
  float: right;
}
.like-action a {
  font-size: 14px;
  color: #0f2444;
  font-family: proximaNovaSemibold;
  cursor: pointer;
}
.like-action svg path {
  fill: black;
  stroke: black;
}
.post-grid {
  padding-right: 0px;
}
.sidebar-navigation .nav-sidebar .nav-item svg path {
  fill: #b3d0fb;
  stroke: #b3d0fb;
}
.post-grid.card .card-footer .input-group-text .a {
  fill: #8c95a2;
  stroke: #8c95a2 !important;
}
.post-grid.card .card-footer .input-group-text svg {
  width: 15px;
  height: 15px;
}
.total-footprints .graphic {
  float: left;
  padding-right: 20px;
}
.total-footprints .footprints {
  float: right;
  padding-left: 0;
}
.total-footprints {
  z-index: 9;
  width: 100%;
  border-radius: 20px;
  min-height: 158px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0 0px 10px;
  box-shadow: 0px 2px 3px #16191e1c;
}
.total-footprints .graphic img {
  width: 100%;
  max-width: 140px;
}
.right-sidebar .sidebar-content {
  position: fixed;
  overflow-y: auto;
  padding-right: 10px;
  margin-bottom: 20px;
}
.total-footprints .footprints small {
  letter-spacing: 0.48px;
  color: #ffffff;
  display: block;
  font-size: 16px;
  font-family: proximaNovaRegular;
  text-transform: capitalize;
}
.total-footprints .footprints .numbers {
  color: #ffffff;
  font-size: 55px;
  font-family: proximaNovaRegular;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 180px;
  display: block;
}
.users-container .users-info {
  list-style-type: none;
  padding-left: 0;
  overflow-y: auto;
  padding-right: 10px;
  /* height: 100%;
  max-height: 520px; */
}
.users-container {
  border-radius: 20px;
  background: white;
  margin-top: 15px;
  padding: 20px 5px 10px 17px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 2px 3px #16191e1c !important;
}
.users-container .users-info .user-name {
  font-size: 14px;
  color: #000000;
  font-family: proximaNovaRegular;
  text-transform: capitalize;
  font-weight: normal;
  display: inline-block;
  white-space: nowrap;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contains-role .users-info .user-name {
  font-size: 14px;
  font-family: proximaNovaSemibold;
}
.users-container .users-info .user-role {
  display: block;
  color: #526178;
  font-size: 13px;
  font-family: proximaNovaRegular;
}
.users-container .users-info .user-details {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  vertical-align: top;
}
.container-title {
  color: #000000;
  font-size: 19px;
  font-family: proximaNovabold;
  padding-bottom: 9px;
}
.users-container .users-info .user-avatar img {
  width: 50px;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 50px;
  object-fit: cover;
}
.users-container .users-info .footprints .footprints-text {
  color: #899bb7;
  font-size: 12px;
  text-transform: capitalize;
  padding-right: 15px;
  vertical-align:/*  -webkit-baseline-middle */ middle;
}
.users-container .users-info .footprints .footprints-numbers {
  background: #eef2f5;
  height: 44px;
  width: 44px;
  display: inline-block;
  border-radius: 11px;
  text-align: center;
  vertical-align: middle;
  padding: 9px 4px 4px 4px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-family: proximaNovaSemibold;
  line-height: 28px;
}
.users-container .users-info li {
  border-bottom: 1px solid #cccccc3d;
  width: 100%;
  padding-top: 7px;
  padding-bottom: 7px;
}
.users-container .users-info::-ms-scrollbar {
  width: 6px;
}
.users-container .users-info::-moz-scrollbar {
  width: 6px;
}
.users-container .users-info::-webkit-scrollbar {
  width: 6px;
}
.users-container .users-info::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.users-container .users-info::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.users-container .users-info::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.users-container .users-info::-ms-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: none;
}
.users-container .users-info::-moz-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: none;
}
.users-container .users-info::-webkit-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: none;
}

.right-sidebar .sidebar-content::-ms-scrollbar {
  width: 6px;
}
.right-sidebar .sidebar-content::-moz-scrollbar {
  width: 6px;
}
.right-sidebar .sidebar-content::-webkit-scrollbar {
  width: 6px;
}
.right-sidebar .sidebar-content::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.right-sidebar .sidebar-content::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.right-sidebar .sidebar-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.right-sidebar .sidebar-content::-ms-scrollbar-thumb {
  background-color: #acb8ca;
  outline: none;
  border-radius: 50px;
}
.right-sidebar .sidebar-content::-moz-scrollbar-thumb {
  background-color: #acb8ca;
  outline: none;
  border-radius: 50px;
}
.right-sidebar .sidebar-content::-webkit-scrollbar-thumb {
  background-color: #acb8ca;
  outline: none;
  border-radius: 50px;
}

.tag-list .odd {
  background: #91c515;
}
.tag-list {
  padding-left: 0;
  list-style: none;
}
.tag-list li {
  display: inline-block;
  background: #5300c1;
  border-radius: 40px;
  text-align: center;
  height: 34px;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 12px;
}
.tag-list li a {
  color: white;
  font-size: 14px;
  font-family: proximaNovaSemibold;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
span.sidebar-arrow {
  position: absolute;
  right: -15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 999999;
}
.sidebar-arrow svg {
  border: 1px solid black;
  background: black;
  padding: 9px;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.main-sidebar.sidebar-visible {
  width: 100px;
}
.sidebar-close .sidebar .user-details .info p {
  margin-bottom: 8px;
}
.sidebar-close .sidebar .user-details .info {
  padding-left: 10px;
  padding-right: 10px;
}
.sidebar-close .nav-sidebar > .nav-item p {
  display: none;
  position: absolute;
  background: black;
  color: white;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border-radius: 3px;
  margin-left: 10px;
}
.sidebar-arrow svg path {
  fill: white;
  stroke: white;
}
.sidebar-close .sidebar-arrow svg {
  transform: rotate(180deg);
}
.main-sidebar {
  overflow-y: visible !important;
}
.content-wrapper.wide-wrapper {
  margin-left: 100px !important;
}
.sidebar-close .nav-item:hover p {
  display: inline-block;
  z-index: 999999;
}
.sidebar-content .radio-container {
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 2px 3px #16191e1c !important;
}
.radio-container {
  border-radius: 20px;
  background: white;
  padding: 20px 15px 10px 17px;
  margin-top: 15px;
}
.radio-container .radio-container-list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
  height: 100%;
  max-height: 147px;
  overflow-y: auto;
}
.radio-container .radio-container-list input[type="checkbox"] {
  margin-right: 10px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox {
  display: inline-block;
  vertical-align: top;
  position: relative;
  padding-left: 26px;
  text-transform: capitalize;
  margin-bottom: 0;
}
.navbar-expand {
  justify-content: center;
}
.navbar-expand .navbar-nav .label-text {
  text-transform: capitalize;
}
.radio-container .radio-container-list li {
  border-bottom: 1px solid #cccccc5c;
  padding: 10px 0 10px 0;
  line-height: 20px;
}
.radio-container .radio-container-list::-moz-scrollbar {
  width: 4px;
}
.radio-container .radio-container-list::-ms-scrollbar {
  width: 4px;
}
.radio-container .radio-container-list::-webkit-scrollbar {
  width: 4px;
}
.radio-container .radio-container-list::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.radio-container .radio-container-list::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.radio-container .radio-container-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.radio-container .radio-container-list::-moz-scrollbar-thumb {
  background-color: #acb8ca;
  outline: 0px solid #acb8ca;
}
.radio-container .radio-container-list::-ms-scrollbar-thumb {
  background-color: #acb8ca;
  outline: 0px solid #acb8ca;
}
.radio-container .radio-container-list::-webkit-scrollbar-thumb {
  background-color: #acb8ca;
  outline: 0px solid #acb8ca;
}
.custom-checkbox input[type="checkbox"] {
  margin-right: 10px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

:not(.layout-fixed) .main-sidebar {
  height: inherit !important;
  min-height: 100% !important;
  position: fixed !important;
  top: 76px !important;
}
.post-description .tag {
  font-weight: 600;
  color: #1d62cb;
  text-transform: capitalize;
  padding-right: 5px;
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  padding-bottom: 5px;
}
.sidebar-close .sidebar-navigation .nav-sidebar .nav-item .nav-link {
  padding-left: 0;
  text-align: center;
}
.nav-sidebar > .nav-item p {
  padding-left: 10px;
  display: inline-block;
}

.sidebar-close .main-sidebar .user-details .image .img-circle {
  width: 50px;
  height: 52px;
  transition: all 0.3s ease-in;
}
.nav-sidebar > .nav-item p,
.sidebar-close .nav-sidebar > .nav-item p {
  transition: all 0.4s linear;
}
.profile-avatar-cover svg,
.main-sidebar .user-details .image .img-circle {
  transition: all 0.3s ease-in;
}
.form-error p {
  margin-bottom: 0;
  font-size: 13px;
  font-family: proximaNovaRegular;
  color: red;
}
.user-form .spinner {
  padding-left: 10px;
}

/* user-profile-page css start */

.user-profile-details .user-location {
  display: inline-block;
  width: 100%;
}
.user-profile-details .user-details .user-grade {
  display: block;
  font-size: 14px;
  color: #8498b5;
  font-family: proximaNovaRegular;
}
.user-profile-details .user-details {
  display: inline-block;
  /* vertical-align: -webkit-baseline-middle;
  padding-left: 12px; */
}
.user-profile-details .userImage-name {
  float: left;
}
.user-profile-details .location {
  float: right;
  padding: 4px 0 0 0;
}
.user-profile-details .user-location .user-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
  border: 1px solid #cccccc45;
}
.user-profile-details .user-details .user-name {
  color: #000000;
  font-family: proximaNovaBold;
  font-size: 22px;
}
.user-profile-details .location .location-text {
  color: #7f8b9c;
  font-size: 16px;
  font-family: proximaNovaRegular;
}
.user-profile-details .location .location-text svg {
  margin-right: 8px;
}
.user-profile-details .user-profile-data li {
  padding-bottom: 5px;
  padding-top: 5px;
}
.user-profile-details .user-profile-data li .data-title {
  color: #000000;
  font-size: 16px;
  font-family: proximaNovaSemibold;
}
.user-profile-details .user-profile-data li .data {
  font-size: 16px;
  color: #7f8b9c;
  font-family: proximaNovaRegular;
}
.school-details {
  text-align: center;
  height: 160px;
  width: 160px;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}
.profile-container .school-details .school-label,
.profile-container .school-details .school-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.profile-container .school-details .school-label {
  width: 140px;
}
.profile-container .school-details .school-name {
  width: 132px;
}
.profile-container .user-location .user-avatar {
  margin-right: 0.5rem;
}

.user-profile-details .user-avatar .align-self-center.img-circle,
.user-profile-details .user-avatar img.align-self-center {
  margin-top: -4px;
}
.user-profile-details .user-location .align-self-center.img-circle,
.user-profile-details .user-location .user-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
  border: 1px solid #cccccc45;
}

.school-details .school-label {
  font-size: 14px;
  font-family: proximaNovaRegular;
}
.school-details .school-name {
  font-size: 17px;
  font-family: proximaNovaSemibold;
  text-align: center;
}
.user-profile-details ul {
  padding-left: 0;
  list-style: none;
}
.contact-details li {
  display: inline-block;
  vertical-align: top;
  padding-right: 30px;
}
.contact-details li .contact-text {
  font-size: 16px;
  color: #000000;
  font-family: proximaNovaRegular;
  margin-left: 9px;
}
.user-interest li {
  display: inline-block;
  color: white;
  margin-right: 10px;
  border-radius: 50px;
  margin-bottom: 10px;
  padding: 2px 11px 4px 11px;
  font-size: 13px;
}
.user-interest .hobbies-list {
  margin-bottom: 7px;
}
.user-interest .hobbies-list li {
  background: #91c515;
}
.user-profile-details .user-interest h6 {
  font-size: 16px;
  color: #000000;
  font-family: proximaNovaSemibold;
}
.user-interest .hobbies-list li a {
  color: white;
  font-size: 14px;
}
.profile-container {
  background: white;
  padding: 20px !important;
  border-radius: 20px;
  margin-bottom: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0px 2px 3px #16191e1c !important;
}
.activities-list li {
  border: 1px solid #707070;
  color: black;
}
.activities-list li a {
  color: black;
  font-size: 14px;
}
.interest-details {
  height: 100%;
  max-height: 358px;
  overflow-y: auto;
}
ul.contact-details {
  margin-bottom: 30px;
}
ul.contact-details .icon .fa {
  color: #000000;
  font-size: 18px;
}
.location .fa {
  color: #7f8b9c;
}
.about-profile .about-description {
  color: #7f8b9c;
  font-size: 16px;
  font-family: proximaNovaRegular;
}
.about-profile .row-title {
  font-size: 20px;
  font-family: proximaNovaSemibold;
  text-transform: capitalize;
}
.about-profile {
  border-bottom: 1px solid #cccccc3b;
}
.other-information {
  padding-top: 25px;
}
.other-information .row-title {
  font-size: 20px;
  font-family: proximaNovaSemibold;
  text-transform: capitaliz;
}
.contact-details li .icon svg {
  width: 18px;
  height: 18px;
}
.user-info {
  padding-left: 10px;
  padding-right: 10px;
}

.edit-profile-content {
  background: white;
}
.edit-profile-content .user-avatar img {
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
  border-top-left-radius: 4px;
  background: transparent linear-gradient(180deg, #f8f8f8 0%, #fcfcfc 100%) 0%
    0% no-repeat padding-box;
  width: 135px;
  height: 118px;
  object-fit: cover;
  border: 1px solid #cccccc57;
}
.edit-profile-content {
  background: white;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  box-shadow: 0px 2px 3px #16191e1c !important;
}
.user-text-area .MuiInputBase-formControl {
  border-radius: 20px;
}
.user-text-area .MuiInputBase-formControl fieldset {
  border: 1px solid #e3e8ec;
}
.edit-about-user .user-text-area {
  width: 100%;
  padding-left: 41px;
}
.edit-avater-icon svg {
  border: 2px solid #ffffff;
  background: #0080c1 0% 0% no-repeat padding-box;
  width: 30px;
  border-radius: 50%;
  height: 30px;
  text-align: center;
  padding: 4px;
  cursor: pointer;
}
.edit-about-user .user-avatar {
  position: relative;
}
.edit-avater-icon {
  position: absolute;
  bottom: 14px;
  right: 6px;
  z-index: 1;
  margin: 0;
}
.user-input-area .MuiInputBase-formControl {
  border-radius: 60px;
}
.user-input-area .MuiInputBase-formControl fieldset {
  border: 1px solid #e3e8ec;
}
.user-text-area label {
  font-size: 14px;
  color: #8498b5;
  font-weight: normal !important;
  font-family: proximaNovaRegular;
}
.user-input-area label {
  font-size: 14px;
  color: #8498b5;
  font-weight: normal !important;
  font-family: proximaNovaRegular;
}
section.user-fields {
  padding-bottom: 30px;
}
.role-fields .section-title {
  font-size: 18px;
  font-family: proximaNovabold;
  padding-bottom: 10px;
}
.role-fields {
  padding-top: 10px;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #8fc30f !important;
}
.form-footer {
  justify-content: flex-end;
  border-top: 1px solid #cccccc36;
  padding-top: 16px;
  padding-bottom: 0;
  padding-right: 0px;
  z-index: 9999;
  margin-top: 10px;
}
.blue-button {
  background: #1d62cb !important;
  border-radius: 50px !important;
  color: white !important;
  min-width: 120px;
  line-height: 22px !important;
  border: 0 !important;
  min-height: 34px;
  opacity: 0.8;
}
.users-container .container-title {
  padding-bottom: 15px;
}
.blue-button:hover {
  opacity: 1;
}
.footer-uploads .icon svg {
  width: 13px;
  height: 13px;
}
.gray-button {
  background: #e3e3e3 !important;
  padding: 8px 35px 8px 35px !important;
  border-radius: 50px !important;
  color: #5b5b5b !important;
  line-height: 22px !important;
}
#creatFootPrintModel .blue-button {
  font-size: 13px;
}
.form-footer .blue-button {
  margin-right: 12px;
}
.student-categories-container {
  background: white;
  border-radius: 20px;
  padding: 20px !important;
  margin-bottom: 20px;
}
.student-or-groups-list {
  margin-bottom: 7px;
  padding: 10px 10px 10px 10px;
  cursor: pointer;
}
.student-or-groups-list img {
  width: 35px;
  background: #f2f4f6;
  border-radius: 20px;
  padding: 6px;
}
.student-or-groups-list .title {
  color: #0f2444;
  font-size: 16px;
  font-family: proximaNovaRegular;
  text-transform: capitalize;
}
.student-or-groups-list:hover {
  background: #154ea5;
  border-radius: 10px;
}
.student-or-groups-list:hover .title {
  color: white;
}
.student-categories-container .scrollable-classes {
  overflow-y: auto;
  height: 100%;
  max-height: 200px;
  margin-top: 18px;
}
.student-categories-container .student-lists {
  padding-left: 0;
  list-style: none;
}
.radio-container .container-title .custom-checkbox {
  font-size: 19px;
  font-family: proximaNovaBold;
}
.student-categories-container .student-lists {
  display: inline-block;
  width: 100%;
  padding: 13px 40px 0px 0px;
}
.student-lists .user-details {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  padding-left: 12px;
  vertical-align: top;
}
.student-lists .user-details small {
  display: block;
}
.student-lists .userImage-name {
  float: left;
}
.student-lists .view-profile {
  float: right;
  padding-top: 18px;
}
.student-lists .user-details .user-name {
  font-size: 16px;
  font-family: proximaNovaSemibold;
  text-transform: capitalize;
}
.student-lists .user-details small {
  display: block;
  color: #0f2444;
  font-size: 14px;
  font-family: proximaNovaRegular;
}
.blue-button-link {
  background: #1d62cb;
  border-radius: 50px;
  color: white;
  padding: 12px 20px 12px 20px;
  font-size: 13px;
  font-family: proximaNovaSemibold;
}
.blue-button-link:hover {
  background: #88c000;
  color: white;
}
.student-categories-container .searched-title {
  font-size: 20px;
  color: #1d62cb;
  font-family: proximaNovaBold;
  padding: 12px 0 0px 0;
}
.page-title {
  font-size: 20px;
  font-family: proximaNovaBold;
  padding: 0 0 6px 0;
}
.scrollable-students-data {
  height: 100%;
  max-height: 50vh;
  overflow-y: auto;
}
.scrollable-classes::-ms-scrollbar {
  width: 8px;
}
.scrollable-classes::-moz-scrollbar {
  width: 8px;
}
.scrollable-classes::-webkit-scrollbar {
  width: 8px;
}
.scrollable-classes::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollable-classes::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollable-classes::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}

.scrollable-classes::-ms-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}

.scrollable-classes::-moz-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}

.scrollable-classes::-webkit-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}

.scrollable-students-data::-ms-scrollbar {
  width: 8px;
}
.scrollable-students-data::-moz-scrollbar {
  width: 8px;
}
.scrollable-students-data::-webkit-scrollbar {
  width: 8px;
}
.scrollable-students-data::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollable-students-data::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollable-students-data::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollable-students-data::-ms-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}
.scrollable-students-data::-moz-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}
.scrollable-students-data::-webkit-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}
.content-loader {
  text-align: center;
  width: 100%;
}
.radio-container label:hover input ~ .checkmark {
  background-color: #1d62cb;
  border: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: white;
  border-radius: 15px;
  border: 1px solid #ccc;
}
.radio-container input:checked ~ .checkmark {
  border: 0;
  background-color: #1d62cb;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
.checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.radio-container .container-title .checkmark {
  top: 3px;
}
.edit-profile-btn svg {
  margin-right: 10px;
}
.edit-profile-btn svg path {
  fill: white;
  stroke: white;
}
.btn-md,
.edit-profile-btn a {
  font-size: 14px;
  font-family: proximaNovaRegular;
  /* padding: 10px 20px 8px 20px !important; */
  text-align: center;
}
.user-input-area input {
  padding: 0px 16px 0px 16px !important;
  height: 43px;
  font-size: 14px;
  font-family: proximaNovaRegular;
}
.user-text-area textarea {
  font-size: 14px;
  font-family: proximaNovaRegular;
}
.edit-profile-btn {
  text-align: right;
}
.chip-box {
  padding: 0 2px;
  margin-bottom: 0.6em;
}
.chip-box .MuiChip-root {
  margin: 0 5px 5px 0;
}
.edit-avater-icon input[type="file"] {
  display: none;
}
.user-input-area .MuiFab-root {
  position: absolute;
  right: 0;
}
.user-input-area .MuiFab-root {
  position: absolute;
  right: 0;
  width: 43px;
  height: 43px;
}
.active-class {
  background: #154ea5;
  border-radius: 10px;
}
.active-class .title {
  color: white;
}
.empty-record {
  width: 100%;
  text-align: center;
  text-transform: capitalize;
}
.student-lists .user-avatar img {
  border-radius: 50%;
  width: 75px;
  height: 75px;
  border: 1px solid #cccccc8c;
  object-fit: cover;
}
.student-categories-container .student-lists::after {
  content: "";
  border-bottom: 1px solid #ccc;
  width: 74%;
  display: block;
  position: absolute;
  right: 58px;
  bottom: 0;
}
.student-or-groups-list .hover-icon {
  display: none;
}
.student-or-groups-list:hover .default-icon {
  display: none;
}
.student-or-groups-list:hover .hover-icon {
  display: inline-block;
}
.active-class .default-icon {
  display: none;
}
.active-class .hover-icon {
  display: inline-block;
}
.profile-avatar-cover {
  position: relative;
  display: inline-block;
}
.profile-avatar-cover svg {
  position: absolute;
  top: -10px;
  right: -15px;
  width: 131px;
  height: 122px;
}
.profile-avatar-cover svg path {
  fill: white;
}
.back-button {
  padding-left: 37px;
  padding-top: 18px;
}
html,
body {
  height: 100% !important;
}
.user-logged-in #root {
  height: auto;
  min-height: 100%;
}
.sidebar-close .sidebar .user-details .info a {
  font-size: 16px;
  line-height: 17px;
  word-break: break-word;
}
.app-options .dropdown-toggle::after {
  position: relative;
  top: 2px;
}
.sidebar-navigation .nav-sidebar .active p {
  text-decoration: underline;
}
.user-input-area label[data-shrink="false"] {
  top: -4px;
}
.form-footer .spinner {
  display: inline-block;
  padding-right: 16px;
  padding-top: 10px;
}

.quick-upload-container .modal-dialog {
  width: 100%;
  max-width: 1175px;
}
.modal-content {
  border-radius: 20px !important;
  box-shadow: 0px 2px 3px #16191e1c !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
}
.modal-content .modal-title {
  font-size: 22px;
  font-family: proximaNovaRegular;
}
.new-post-container .user-avatar img {
  border-radius: 50px;
  object-fit: cover;
  width: 43px;
  height: 43px;
}
.new-post-container .user-details .user-name {
  font-size: 14px;
  font-family: proximaNovabold;
}
.new-post-container .new-post-area {
  margin-top: 15px;
}
.new-post-area .form-group textarea {
  resize: none;
  border: 0;
  padding-left: 0;
  font-size: 18px;
  font-family: proximaNovaRegular;
  background: #f8f8f8 0% 0% no-repeat padding-box;
}
.new-post-area .form-group textarea:focus {
  outline: none;
  box-shadow: none;
}
.new-post-container {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  height: 100%;
  padding: 20px 0 0 20px;
}
.modal-content .new-post-container .userImage-name {
  height: 43px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.quick-upload-container .modal-body {
  padding-left: 0;
  padding-bottom: 0;
  padding-top: 0;
}
.selection-container {
  margin-top: 15px;
  padding-left: 10px;
}
.selection-container .users-list-items .checkmark {
  margin: auto;
  bottom: 0;
}
.selection-container label {
  font-size: 16px !important;
  font-family: proximaNovaSemibold;
}
.selection-container select {
  font-size: 14px;
  font-family: proximaNovaRegular;
}
.users-selection {
  padding: 0;
}
.search-input .form-control {
  display: none;
}
.users-selection .search-user .container-title {
  float: left;
}
.users-selection .search-user .search-input {
  float: right;
  width: auto;
}
.users-selection .search-user {
  display: inline-block;
  width: 100%;
}
.users-selection .user-details {
  display: inline-block;
  vertical-align: -webkit-baseline-middle;
  padding-left: 12px;
  vertical-align: top;
  padding-top: 10px;
}
.users-selection .userImage-name {
  float: left;
}
.users-selection .userImage-name .user-avatar img {
  width: 44px;
  height: 44px;
  object-fit: cover;
  border-radius: 50px;
  border: 1px solid #cccccc5e;
}
.users-selection ul {
  padding-left: 0;
  list-style: none;
}
.users-list-items li {
  display: flex;
  padding-right: 10px;
}
.users-selection .user-name {
  font-size: 16px;
  font-family: proximaNovaRegular;
  text-transform: capitalize;
  font-weight: normal;
}
.users-list-items .list {
  display: block;
  width: 100%;
  background: #efefef 0% 0% no-repeat padding-box;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin: 0 0 10px 0;
}

.uploaded-asstes li {
  display: inline-block;
  margin-right: 0px;
  margin-bottom: 6px;
}
.uploaded-asstes img {
  width: 100%;
  max-width: 60px;
  border-radius: 10px;
}
.users-selection .uploaded-asstes {
  padding-left: 50px;
}
.users-selection .users-list-items {
  padding-left: 0;
  list-style: none;
  /* max-height: 280px; */
  height: 100%;
  overflow-y: auto;
}
.upload-btns button {
  background: transparent;
  border: 0;
}
.upload-btns .media-upload:hover {
  background: #91c515 0% 0% no-repeat padding-box !important;
}
.upload-btns .media-upload {
  border-radius: 50px !important;
  line-height: 0;
  background: #91c515 0% 0% no-repeat padding-box;
  width: 30px;
  height: 30px;
  padding: 0;
}
.upload-btns .media-upload path {
  fill: white;
}
.upload-btns .google-drive {
  background: white;
  border-radius: 50px !important;
  margin-left: 10px !important;
  width: 30px;
  height: 30px;
  padding: 0;
}
.upload-btns small {
  color: #878787;
  font-size: 11px;
  line-height: 40px;
  margin-right: 12px;
}
.upload-btns {
  float: right;
  padding-top: 8px;
  align-items: center;
}
.upload-btns svg {
  width: 12px;
  height: 12px;
}
.modal-footer .blue-button {
  width: 100%;
  max-width: 120px !important;
  font-size: 14px;
}
.footer-uploads {
  position: absolute !important;
  left: 0;
}
.no-abs-footer .footer-uploads {
  position: relative !important;
  left: 0;
}
.footer-uploads .media-upload,
.footer-uploads .google-drive {
  margin-right: 5px;
}
.footer-uploads .media-upload .icon,
.footer-uploads .google-drive .icon {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: inline-block;
  line-height: 28px;
}
.footer-uploads .media-upload .icon {
  background: #91c515;
}
.footer-uploads .google-drive .icon {
  background: #e5e5e5;
}
.footer-uploads .media-upload .icon path {
  fill: white;
}
.footer-uploads .upload-files {
  display: block;
  padding-left: 15px;
  padding-top: 5px;
  color: #747f8f;
  font-size: 13px;
  font-family: proximaNovaSemibold;
}
.modal {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.sidebar-navigation .nav-sidebar .nav-item .nav-link .fa {
  font-size: 17px;
  margin-right: 0;
  vertical-align: text-bottom;
  width: 21px;
}
.create-footprint-form .user-image {
  position: relative;
  /*   z-index: 99; */
}
.create-footprint-form .user-image img {
  width: 43px;
  height: 43px;
  object-fit: cover;
  border: 1px solid #cccccc8c;
  border-radius: 50px;
}
.create-footprint-form input {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 21px;
}
.footprint-creator {
  padding: 20px;
}
/*Quick upload css start*/
.quick-upload-container .modal-footer {
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
}
.selection-container select {
  border-radius: 0.5rem;
}
.selection-container select/* ,
.selection-container select::placeholder */ {
  color: #1d62cb;
}
.selection-container .users-list-items .checkmark {
  margin: 4px auto 0 auto;
}
.fa-search {
  color: #16191eb5;
}
.login-header svg {
  width: 190px;
}
.app-logo svg {
  width: 180px;
}
.app-footer .links-list li {
  display: inline-block;
  padding: 0px 11px 0 11px;
}
.app-footer .links-list li a {
  font-size: 14px;
  color: #818181;
  font-family: proximaNovaRegular;
}
.app-footer .links-list {
  border-top: 1px solid #ccc;
  text-align: center;
  padding: 25px 0 0 0;
}
.app-footer {
  margin: 60px 0 0 0px;
}
.sidebar-close .profile-avatar-cover svg {
  position: absolute;
  top: -6px;
  right: -7px;
  width: 65px;
  height: 66px;
}
.app-search-field button svg {
  width: 15px;
  height: 15px;
}
.student-categories-container button {
  padding-top: 2px;
}
a:hover {
  text-decoration: unset;
}
.user-interest a {
  pointer-events: none;
}
.back-button svg {
  background: #0080c1;
  color: white;
  width: 30px;
  border-radius: 50px;
  font-size: 13px;
  height: 30px;
  padding: 9px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modal-dialog {
    transition: all 0.5s !important;
    position: absolute !important;
    left: 50%;
    right: 50%;
    margin: 0 !important;
    top: 50%;
    transform: translate(-50%, -50%) !important;
  }
}

/*quick upload css end*/
@media all and (min-width: 768px) and (max-width: 994px) {
  .users-container .users-info .footprints {
    padding-top: 0px;
  }
  .users-container .users-info .userImage-name {
    float: none;
  }
  .total-footprints .footprints .numbers {
    width: 120px;
  }
}

@media all and (min-width: 1650px) {
  .main-theme {
    max-width: 1400px;
    margin: auto;
  }
  .main-header {
    width: 1400px;
    margin: auto !important;
  }
  .right-sidebar .sidebar-content {
    width: 360px;
  }
  .student-categories-container .student-lists::after {
    width: 387px;
  }
}

@media all and (min-width: 1367px) {
  .login-wrapper .right-section {
    display: flex;
    align-items: center;
  }
}

@media all and (max-width: 1500px) {
  .total-footprints {
    min-height: 98px;
    padding: 15px;
  }
}
@media all and (max-width: 1366px) {
  .login-wrapper .right-section .login-form {
    padding-top: 10%;
    max-width: 544px;
  }
}
@media all and (max-width: 1250px) and (min-width: 1040px) {
  .login-wrapper .right-section .login-form {
    max-width: 520px;
  }
}
@media all and (max-width: 1199px) {
  .users-container .users-info .footprints .footprints-numbers {
    height: 30px;
    width: 30px;
    padding: 1px 2px 1px 1px;
    font-size: 12px;
  }
  .users-container .users-info .user-avatar img {
    width: 30px;
    height: 30px;
  }
  .users-container .users-info .footprints .footprints-text {
    font-size: 10px;
    padding-right: 4px;
    padding-top: 1px;
  }
  .student-lists .userImage-name {
    float: none;
  }
  .student-lists .view-profile {
    float: none;
    padding-top: 10px;
    padding-left: 20px;
  }
  .quick-upload-container .selection-container {
    height: 99% !important;
    overflow-y: auto;
  }
}
@media all and (max-width: 1200px) {
  .quick-upload-container .modal-body {
    padding-right: 0;
  }
}
@media all and (min-width: 1200px) {
  .quick-upload-container .full-height-txtarea {
    height: 30vh;
  }
}

@media all and (max-width: 1024px) {
  .right-sidebar .sidebar-content {
    position: unset;
  }
  .login-wrapper {
    overflow: auto;
  }
  .login-wrapper .right-section .login-form {
    padding: 30px;
    margin: auto;
  }
  .login-wrapper .left-section {
    height: 100% !important;
    background-position: right !important;
  }
}

@media all and (max-width: 991px) {
  .profile-avatar-cover svg {
    position: absolute;
    top: -8px;
    right: -15px;
    width: 94px;
    height: 81px;
  }
  span.sidebar-arrow {
    display: none;
  }
  .login-wrapper .login-form .welcome-user h1 {
    font-size: 30px;
  }
  .login-wrapper .login-form .welcome-user p {
    font-size: 13px;
  }
  .total-footprints .footprints .numbers {
    font-size: 39px;
  }

  .total-footprints .footprints small {
    font-size: 10px;
  }

  .total-footprints .graphic img {
    width: 100%;
    max-width: 80px;
  }
  .main-sidebar {
    margin-left: -250px;
  }
  .main-header .mobile-toggle {
    display: block !important;
    width: 33.33%;
  }
  .sidebar-open {
    margin-left: 0 !important;
  }
  .search-plus-menu .app-search-field {
    display: none;
  }
  .app-logo {
    width: 33.33%;
    max-width: 100%;
    text-align: center;
    padding-left: 0;
  }
  .search-plus-menu {
    width: 33.33%;
  }
  .main-sidebar .user-details {
    padding-top: 15px;
  }
  .sidebar .user-details .info {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .sidebar .user-details .image .img-circle {
    width: 65px;
    height: 65px;
  }
  .sidebar-navigation {
    padding-top: 20px;
  }
  .content-wrapper > .content {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

@media all and (max-width: 767px) {
  .app-logo svg {
    width: auto;
  }
  .main-sidebar {
    /*  overflow-y: auto !important; */
  }
  .users-selection .userImage-name {
    float: none;
  }
  .upload-btns {
    float: none;
    height: auto;
    padding-top: 11px;
  }
  .student-categories-container .student-lists::after {
    width: 100%;
    right: 0;
  }
  .post-grid {
    padding-right: 0;
  }
  .forgot-pass {
    position: unset;
    display: block;
    padding-top: 20px;
  }
  .right-sidebar .sidebar-content {
    padding-right: 0;
  }
  .user-info {
    padding-left: 0;
    padding-right: 0;
  }
  .user-profile-details {
    margin-top: 20px;
  }
  .interest-details {
    max-height: 100%;
  }
  .edit-profile-btn {
    text-align: left !important;
    margin-bottom: 20px;
  }
}

@media all and (max-width: 500px) {
  .users-selection .users-list-items {
    max-height: 100%;
  }
  .upload-btns {
    display: block;
    margin-bottom: 11px;
  }
  .new-post-area .form-group textarea {
    font-size: 13px;
  }
  .edit-about-user .user-avatar {
    max-width: 125px;
    margin: auto auto 20px auto;
  }
  .edit-about-user {
    display: block !important;
  }
  .edit-about-user .user-text-area {
    padding-left: 0;
  }
  .footer-uploads {
    position: unset !important;
    width: 100%;
  }
  .footer-uploads .button-text {
    font-size: 10px;
  }
  .modal-dialog {
    margin: 0.2rem auto !important;
  }
}

@media all and (max-width: 400px) {
  .login-wrapper .login-form .welcome-user h1 {
    font-size: 20px;
  }
  .user-input-label {
    font-size: 14px;
  }
  .action-button .btn {
    height: 35px;
    line-height: 26px;
    max-width: 125px;
    font-size: 14px;
  }
  .app-options .dropdown-toggle {
    font-size: 9px;
  }
  .app-options .user-avatar {
    width: 25px !important;
    height: 25px !important;
  }
  .tag-list li {
    display: block;
    margin-bottom: 10px;
  }
}
.picker-dialog {
  z-index: 2000 !important;
}
.overlay-main {
  position: initial;
}
._loading_overlay_overlay {
  border-radius: 19px;
  background: rgba(0, 0, 0, 0.6);
}
.swiper-wrapper {
  margin-bottom: 20px;
}
.active.item {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #91c515;
  border-radius: 100%;
  width: 45px;
  height: 45px;
}
.user-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.user_img_container {
  height: 45px;
  border-radius: 100%;
  width: 45px;
}
