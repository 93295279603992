a,
.btn,
button,
input[type="button"],
a:hover,
.btn:hover,
button:hover,
input[type="button"]:hover,
a:focus,
.btn:focus,
button:focus,
input[type="button"]:focus {
  outline: none;
  box-shadow: none;
}
.unstyled {
  list-style: none;
  padding: 0;
  height: 60px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.unstyled li {
  min-width: 45px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin: 0 5px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unstyled li img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  background: #fff;
  border-radius: 50%;
  border: 1px solid transparent;
  padding: 2px;
  -webkit-tap-highlight-color: #88c000;
}
.unstyled li.active img {
  border: 1px solid #88c000;
}
.unstyled::-moz-scrollbar {
  height: 5px;
}
.unstyled::-ms-scrollbar {
  height: 5px;
}
.unstyled::-webkit-scrollbar {
  height: 5px;
}
.unstyled::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.unstyled::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.unstyled::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.unstyled::-ms-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: none;
}
.unstyled::-moz-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: none;
}
.unstyled::-webkit-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: none;
}
.contact-details {
  margin-top: 13px;
}
.modal-footer.no-abs-footer {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.modal-footer.no-abs-footer .MuiChip-colorPrimary {
  background-color: #e4edf9;
}
.modal-footer.no-abs-footer .MuiChip-colorPrimary:hover {
  background-color: #1d62cb;
}
.modal-footer.no-abs-footer .uploaded-asstes {
  padding: 0;
  width: 100%;
  max-height: 43px;
  margin: 0;
  overflow-y: auto;
}
.no-abs-footer > .row {
  width: 100%;
}
.user-input-area input::placeholder,
.tag-lable label,
.MuiInputBase-input::placeholder,
.select-bootstrap div::placeholder,
#outlined-multiline-static::placeholder {
  color: #495057 !important;
}
.sidebar-content .users-container .users-info .user-avatar img,
.sidebar-content .users-info .userImage-name .user-avatar {
  width: 44px;
  height: 44px;
}
.comment-date {
  font-family: proximaNovaRegular;
  font-size: 12px;
}
.common-theme .main-sidebar {
  transition: all 0.5s linear;
}
button .spinner {
  margin-left: 9px;
  display: inline-block;
}
.linear-spinner {
  margin-right: -15px;
}
.MuiSnackbar-anchorOriginBottomCenter {
  width: max-content;
}
.modal-footer.no-abs-footer .media-upload,
.modal-footer.no-abs-footer .footer-uploads .upload-files {
  padding-left: 0px;
}
.std-footprint-mdl .uploaded-asstes .MuiChip-colorPrimary {
  background-color: #c7d3e3;
}
.std-footprint-mdl .uploaded-asstes .MuiChip-colorPrimary:hover {
  background-color: #0080c1;
}
.std-footprint-mdl .uploaded-asstes .MuiChip-label {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.std-footprint-mdl .uploaded-asstes {
  padding: 0;
  margin-bottom: 3px;
  max-height: 66px;
  overflow-x: hidden;
  overflow-y: auto;
}
.btn.google-drive svg {
  vertical-align: initial;
}
.footer-uploads .media-upload svg path {
  fill: white;
  stroke: white;
}
.main-sidebar {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.student-list {
  margin-top: 6px;
}
.student-list .user-name {
  white-space: nowrap;
  width: 42px;
  display: inline-block;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.student-list .owl-carousel .owl-item .active img {
  border: 2px solid #91c515;
}
.student-list .owl-carousel .owl-item img {
  cursor: pointer;
  object-fit: cover;
  padding: 0px;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  border: 2px solid transparent;
}
.users-container .users-info li {
  display: flex;
  opacity: 1;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}
.users-container .users-info li .user-avatar {
  margin-right: 12px;
  display: inline-block;
}
.users-container .users-info li .footprints {
  text-align: end;
}
.users-container .users-info .user-details {
  margin-right: 5px;
}

.post-grid .card .card-body .post-image .carosel-item {
  background: #eef2f5;
  border: 1px solid #eef2f5;
}
.post-grid .card .card-body .post-image #footPrintGallery .carosel-item {
  border: 0;
}
#footPrintGallery .modal-content {
  border-radius: 0 !important;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5) !important;
}
#footPrintGallery .modal-content img {
  max-width: 80vw;
  width: auto;
  max-height: 90vh;
}

.carosel-item iframe,
.carosel-item html {
  background: #fff;
}
.carosel-item iframe {
  margin-bottom: -6px;
}
.document-placeholder {
  min-height: 180px;
  height: 229px;
}
.video-player,
.post-img-holder {
  height: auto;
}
.video-player {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.video-player-expand {
  position: absolute;
  right: 3px;
  top: 3px;
  border: none;
  background: none;
  color: white;
  text-shadow: 0 0 4px black;
}
@-moz-document url-prefix() {
  .video-player-expand {
    right: 1px;
    padding: 9px 14px;
    bottom: 3px;
    background: rgba(0, 0, 0, 0.9);
    top: auto;
  }
  .video-player-expand:hover {
    background: rgba(0, 0, 0, 0.9);
  }
  .cover-last {
    display: block !important;
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    background: rgba(0, 0, 0, 0.9);
    padding: 10px 9px;
  }
}
.video-player-expand:hover {
  /* background: none; */
}
.cover-last {
  display: none;
}
.post-grid .card .card-body .post-image .video-player video {
  width: 100%;
  outline: none;
}
.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #7ca94a;
}

.school-details .school-name {
  margin-bottom: 0;
}
:not(.layout-fixed) .main-sidebar {
  z-index: 9;
}
.select-bootstrap svg {
  display: none !important;
}
.owl-carousel {
  padding-left: 1px;
  padding-right: 1px;
  box-sizing: border-box;
}
.owl-carousel .owl-item img {
  width: auto;
  max-height: 500px;
  display: inline-block;
}
.modal-footer.no-abs-footer button .button-text {
  display: none;
}
.modal-footer.no-abs-footer .btn {
  padding: 0.375rem 0.1rem 0.375rem 0;
}
.modal-footer.no-abs-footer .MuiButtonBase-root.MuiFab-root {
  margin-left: 0px !important;
}
.card.comment-card .media .img-circle {
  width: 37px;
  height: 37px;
  margin-right: 1rem !important;
  align-self: flex-start !important;
}
.large-icon img {
  max-width: 160px;
}
.files-icon-holder {
  display: table;
  width: 100%;
}
.large-icon {
  display: table-cell;
  vertical-align: middle;
}
.ftr {
  padding-top: 10px;
  padding-bottom: 10px;
}
.large-icon .btn {
  font-size: 12px;
  background-color: #1d62cb;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 50px !important;
  border: 0 !important;
  opacity: 0.8;
}
.large-icon .btn:focus,
.large-icon .btn:hover {
  opacity: 1;
}
.ref-menu-btn button {
  padding: 0;
}

.no-focus:hover,
.no-focus:focus {
  outline: none !important;
  border-color: rgba(0, 0, 0, 0.01);
  background-color: #f2f4f6;
}
.comment-form textarea.form-control:focus + .input-group-text,
.comment-form input.form-control:focus + .input-group-text {
  background-color: white;
}
.comment-form textarea.form-control:focus,
.comment-form input.form-control:focus {
  background-color: #fff !important;
}
.comment-form
  textarea.form-control:focus
  + .input-group-prepend
  .input-group-text,
.comment-form
  input.form-control:focus
  + .input-group-prepend
  .input-group-text {
  border-color: #5aa8fb;
  box-shadow: 1px 0px 0px 0px #5aa8fb !important;
  background-color: white;
}

.search-plus-menu
  .app-search-field
  .input-group
  .form-control.form-control-navbar:focus {
  border-right: 0;
}
.app-search-field .input-group .form-control.form-control-navbar:focus {
  border: 1px solid #5aa8fb;
}

.search-plus-menu
  .app-search-field
  .input-group
  .form-control.form-control-navbar:focus
  + .input-group-append
  .btn-navbar {
  border-left: 0;
}
.app-search-field
  .input-group
  .form-control.form-control-navbar:focus
  + .input-group-append
  .btn-navbar {
  border: 1px solid #5aa8fb;
}
.one-line-txt {
  max-width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.std-search .user-avatar img {
  max-width: 75px;
}
.modal-content .overlay-main,
.modal-content ._loading_overlay_wrapper.overlay-main {
  position: initial;
}
.users-container .users-info .user-name {
  margin-top: 5px;
}
.rgt-mini-loader {
  display: inline-block;
}
.post-container-head {
  justify-content: flex-start;
  display: flex;
  align-items: center;
}
.single-image {
  /*   height:  100%; */
  width: 100%;
  /*  display: flex;
  align-items: center;
  justify-content: center; */
}
.footprint-menu-rgt {
  position: absolute;
  right: 3px;
  top: 20px;
}
.ref-menu-btn button,
.footprint-menu-rgt button {
  border-radius: 0;
}
.ref-menu-btn button:hover,
.footprint-menu-rgt button:hover {
  background: none;
  color: #91c515;
}
.single-image img {
  width: auto;
  max-height: 100vh;
}
.video-player {
  background: #000;
}
.video-player video {
  max-height: 80vh;
}
.footprint-card-body {
  padding-bottom: 0;
  margin-bottom: -3px;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  width: auto;
  bottom: -38px;
  display: inline-block;
  position: absolute;
  left: 45px;
  right: 45px;
}
#footPrintGallery .close {
  position: absolute;
  right: -9px;
  top: -6px;
  z-index: 9;
  color: white;
  background: rgba(0, 0, 0, 1);
  border-radius: 50%;
  padding: 1px 6px 6px 6px;
  font-size: 18px;
  font-weight: 100;
  line-height: 9px;
  opacity: 1;
  width: 22px;
  height: 22px;
}
.right-spinner {
  padding-right: 10px;
  padding-top: 2px;
}
.controls-bar.top-left {
  display: none;
}
.btn-mini {
  width: 66px;
  min-width: 60px;
  padding: 5px 5px;
  float: right;
  font-size: 12px;
  line-height: 14px !important;
  height: 28px;
  min-height: 26px;
}
.scrollable {
  overflow-y: auto !important;
  height: 100%;
}

.scrollable::-ms-scrollbar {
  width: 8px;
}
.scrollable::-moz-scrollbar {
  width: 8px;
}
.scrollable::-webkit-scrollbar {
  width: 8px;
}
.scrollable::-moz-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollable::-ms-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}
.scrollables::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #d0d8e5;
  box-shadow: inset 0 0 6px #d0d8e5;
}

.scrollable::-ms-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}

.scrollable::-moz-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}

.scrollables::-webkit-scrollbar-thumb {
  background-color: #d0d8e5;
  outline: 1px solid #f5f3f2;
}
::-moz-media-controls-fullscreen-button,
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
.info-btn {
  display: inline-block;
}
.info-btn button {
  background: white;
  color: rgb(0, 128, 193);
  box-shadow: none;
  height: 18px;
  min-height: 18px;
  margin-bottom: 0;
  margin-top: -2px;
}
button#fullscreenButton.button.fullscreenButton {
  display: none;
}

.controlBar .fullscreenButton,
.fullscreenButton {
  display: none;
}
.no-video-content {
  position: absolute;
  background: #181717;
  top: 0;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  z-index: 9;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: white;
}
.relative {
  position: relative;
}
.checkbox-row.radio-container {
  margin: 0;
  padding: 0;
}
.checkbox-row.radio-container label {
  font-weight: 600;
  line-height: 1.4;
  font-size: 14px;
  font-family: proximaNovaRegular;
}
.checkbox-row.radio-container label p {
  margin: 0;
}
.landing-page-bg {
  text-align: center;
  background: url("../assets/images/landin-bg.png") no-repeat right bottom
    #0eb586;
  background-size: cover;
  max-height: 100%;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  position: relative;
  color: white;
  padding-bottom: 160px;
}
.landing-page-bg .btn {
  border-radius: 138px;
  height: 52px;
  line-height: 42px;
  background: #91c515 0% 0% no-repeat padding-box;
  border: 0;
  width: 100%;
  min-width: 200px;
  font-size: 26px;
  font-family: proximaNovaSemibold;
  opacity: 0.8;
}
.landing-page-bg .btn:hover {
  opacity: 0.9;
}
.landing-page-bg .bottom-row {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  right: 0;
}
.bottom-row .col-md-12 p {
  margin-top: 40px;
  margin-bottom: 40px;
}
.bottom-row .col-md-12 a {
  font-weight: 500;
  color: #fff;
}
.bottom-row .col-md-12 a:hover,
.bottom-row .col-md-12 a:visited {
  text-decoration: underline;
  color: #fff;
}
.img-app {
  margin-bottom: 0.4rem;
  margin-top: 30px;
}

.landing-page-bg .bottom-row hr {
  border-color: rgba(255, 255, 255, 0.3);
}
.landing-page-bg footer {
  text-align: center;
  background: white;
  color: gray;
  padding: 12px 10px;
}
.landing-page-bg footer ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.landing-page-bg footer ul li {
  display: inline-block;
  padding: 0 10px;
  color: gray;
  font-weight: 600;
  position: relative;
}
.landing-page-bg footer ul li:first-child::after {
  content: " ";
  border-right: 1px solid gray;
  position: absolute;
  top: 6px;
  bottom: 4px;
  right: 0;
}
.landing-page-bg footer ul li a {
  color: gray;
}

.landing-page-bg .intro {
  font-size: 1.38rem;
  margin-bottom: 2.8rem;
  text-align: center;
}
.landing-page-bg .container {
  max-width: 80%;
  margin-top: 2.6rem;
}
.landing-page-bg .main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 1140px) and (min-height: 800px) {
  .landing-page-bg .main-content {
    height: calc(90vh - 150px);
  }
}
@media (max-width: 768px) {
  .landing-page-bg .main-content h1 {
    font-size: 2rem;
  }
  .landing-page-bg .intro {
    font-size: 1rem;
    margin-bottom: 1.2rem;
  }
  .landing-page-bg .action-btn {
    margin-bottom: 1.2rem !important;
  }
  .landing-page-bg .btn {
    font-size: 1.2em;
    height: auto;
    line-height: inherit;
  }
  .landing-page-bg h2 {
    font-size: 1rem;
  }
  .img-app {
    margin-top: 10px;
  }
}
